import type {
  FetchCaptchaCodePayload,
  FetchSocialContactPayload,
  FetchSocialContactResponseData,
  FetchSystemSettingsPayload,
  FetchSystemSettingsResponseData,
  SubmitContactPayload,
  SubscribeAppPayload,
} from "@/utils/apis/common";

export enum CommonActionTypes {
  FETCH_REQUESTED = "@@common/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@common/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@common/FETCH_FAILED",

  // Saga
  FETCH_CAPTCHA_CODE_SAGA = "@@common/FETCH_CAPTCHA_CODE_SAGA",
  FETCH_SOCIAL_CONTACT_SAGA = "@@common/FETCH_SOCIAL_CONTACT_SAGA",
  FETCH_SYSTEM_SETTINGS_SAGA = "@@common/FETCH_SYSTEM_SETTINGS_SAGA",
  SUBSCRIBE_APP_SAGA = "@@common/SUBSCRIBE_APP_SAGA",
  SUBMIT_CONTACT_SAGA = "@@common/SUBMIT_CONTACT_SAGA",
}

// State

export interface CommonState {
  socialContact: FetchSocialContactResponseData | null;
  socialContactLoading: boolean;
  socialContactError: string;

  systemSettings: FetchSystemSettingsResponseData | null;
  systemSettingsLoading: boolean;
  systemSettingsError: string;

  captchaCode: string;
  captchaCodeLoading: boolean;
  captchaCodeError: string;
}

// ---- Reducer Action ----

export type FetchScope = "socialContact" | "systemSettings" | "captchaCode";

export type FetchRequestedAction = {
  type: CommonActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: CommonActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CommonState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: CommonActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchSocialContactSagaAction = {
  type: CommonActionTypes.FETCH_SOCIAL_CONTACT_SAGA;
  payload?: FetchSocialContactPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchSystemSettingsSagaAction = {
  type: CommonActionTypes.FETCH_SYSTEM_SETTINGS_SAGA;
  payload?: FetchSystemSettingsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type SubscribeAppSagaAction = {
  type: CommonActionTypes.SUBSCRIBE_APP_SAGA;
  payload: SubscribeAppPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type SubmitContactSagaAction = {
  type: CommonActionTypes.SUBMIT_CONTACT_SAGA;
  payload: SubmitContactPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchCaptchaCodeSagaAction = {
  type: CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA;
  payload?: FetchCaptchaCodePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CommonAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchSocialContactSagaAction
  | FetchCaptchaCodeSagaAction
  | SubscribeAppSagaAction
  | SubmitContactSagaAction;
