import type {
  FetchSchoolResponseData,
  FetchSchoolsPayload,
  FetchSchoolsPayloadParams,
  FetchSchoolsResponseData,
} from "@/utils/apis/school";

export enum SchoolActionTypes {
  FETCH_REQUESTED = "@@school/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@school/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@school/FETCH_FAILED",

  FETCH_SCHOOL_SUCCEEDED_SERVER = "@@school/FETCH_SCHOOL_SUCCEEDED_SERVER",

  // Saga
  FETCH_SCHOOL_SAGA = "@@school/FETCH_SCHOOL_SAGA",
  FETCH_SCHOOLS_SAGA = "@@school/FETCH_SCHOOLS_SAGA",
  FETCH_RECOMMENDED_SCHOOLS_SAGA = "@@school/FETCH_RECOMMENDED_SCHOOLS_SAGA",
  FETCH_ADMISSION_DATE_SCHOOLS_SAGA = "@@school/FETCH_ADMISSION_DATE_SCHOOLS_SAGA",
}

// State

export interface SchoolState {
  hydrated?: boolean;

  schools: FetchSchoolsResponseData["School"];
  schoolsLoading: boolean;
  schoolsError: string;
  schoolsCount: number;

  school: FetchSchoolResponseData | null;
  schoolLoading: boolean;
  schoolError: string;

  recommendedSchools: FetchSchoolsResponseData["School"];
  recommendedSchoolsLoading: boolean;
  recommendedSchoolsError: string;
  recommendedSchoolsCount: number;

  admissionDateSchools: FetchSchoolsResponseData["School"];
  admissionDateSchoolsLoading: boolean;
  admissionDateSchoolsError: string;
  admissionDateSchoolsCount: number;
}

// ---- Reducer Action ----

export type FetchScope =
  | "schools"
  | "recommendedSchools"
  | "admissionDateSchools"
  | "school";

export type FetchRequestedAction = {
  type: SchoolActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: SchoolActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: SchoolState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: SchoolActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchSchoolSucceededServerAction = {
  type: SchoolActionTypes.FETCH_SCHOOL_SUCCEEDED_SERVER;
  payload: FetchSchoolResponseData | null;
};

// ---- Saga Action ----

export type FetchSchoolsSagaAction = {
  type: SchoolActionTypes.FETCH_SCHOOLS_SAGA;
  payload?: FetchSchoolsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchRecommendedSchoolsSagaAction = {
  type: SchoolActionTypes.FETCH_RECOMMENDED_SCHOOLS_SAGA;
  payload?: Pick<FetchSchoolsPayload, "cancelToken"> & {
    params?: Partial<Omit<FetchSchoolsPayloadParams, "is_recommend">>;
  };
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchAdmissionDateSchoolsSagaAction = {
  type: SchoolActionTypes.FETCH_ADMISSION_DATE_SCHOOLS_SAGA;
  payload: Pick<FetchSchoolsPayload, "cancelToken"> & {
    params: Pick<FetchSchoolsPayloadParams, "admission_date"> &
      Partial<Pick<FetchSchoolsPayloadParams, "page" | "limit">>;
  };
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type SchoolAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchSchoolSucceededServerAction
  //
  | FetchSchoolsSagaAction
  | FetchRecommendedSchoolsSagaAction
  | FetchAdmissionDateSchoolsSagaAction;
