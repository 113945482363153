import { TagActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchTagsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: TagActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: TagActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: TagActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchTagsSaga = (
  payload?: FetchTagsSagaAction["payload"],
  meta?: FetchTagsSagaAction["meta"]
): FetchTagsSagaAction => ({
  type: TagActionTypes.FETCH_TAGS_SAGA,
  payload,
  meta,
});
