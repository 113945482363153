import { TipCategoryActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchTipCategoriesSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: TipCategoryActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: TipCategoryActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: TipCategoryActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchTipCategoriesSaga = (
  payload?: FetchTipCategoriesSagaAction["payload"],
  meta?: FetchTipCategoriesSagaAction["meta"]
): FetchTipCategoriesSagaAction => ({
  type: TipCategoryActionTypes.FETCH_TIP_CATEGORIES_SAGA,
  payload,
  meta,
});
