import { SchoolActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { SchoolState, SchoolAction } from "./types";

export const initialState: SchoolState = {
  hydrated: false,

  school: null,
  schoolError: "",
  schoolLoading: true,

  schools: [],
  schoolsError: "",
  schoolsLoading: true,
  schoolsCount: 0,

  recommendedSchools: [],
  recommendedSchoolsError: "",
  recommendedSchoolsLoading: true,
  recommendedSchoolsCount: 0,

  admissionDateSchools: [],
  admissionDateSchoolsError: "",
  admissionDateSchoolsLoading: true,
  admissionDateSchoolsCount: 0,
};

const reducer = (state = initialState, action: SchoolAction): SchoolState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { school, schoolError, schoolLoading, hydrated } = (action as any)
        .payload.school as SchoolState;

      const newState = {
        school,
        schoolError,
        schoolLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case SchoolActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case SchoolActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every((stateDataItem) => {
            if (["schools", "admissionDateSchools"].includes(scope))
              return stateDataItem.id === item.id;
            return false;
          });
        });
        newData = [...stateData, ...(filteredData as any)] as any;
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case SchoolActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case SchoolActionTypes.FETCH_SCHOOL_SUCCEEDED_SERVER: {
      return {
        school: action.payload,
        schoolLoading: false,
        schoolError: "",
        hydrated: true,
      } as Partial<SchoolState> as SchoolState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
