import type {
  FetchTipResponseData,
  FetchTipsPayload,
  FetchTipsResponseData,
} from "@/utils/apis/tip";

export enum TipActionTypes {
  FETCH_REQUESTED = "@@tip/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@tip/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@tip/FETCH_FAILED",

  FETCH_TIP_SUCCEEDED_SERVER = "@@tip/FETCH_TIP_SUCCEEDED_SERVER",

  // Saga
  FETCH_TIP_SAGA = "@@tip/FETCH_TIP_SAGA",
  FETCH_TIPS_SAGA = "@@tip/FETCH_TIPS_SAGA",
}

// State

export interface TipState {
  hydrated?: boolean;

  tips: FetchTipsResponseData["Tip"];
  tipsLoading: boolean;
  tipsError: string;
  tipsCount: number;

  tip: FetchTipResponseData | null;
  tipLoading: boolean;
  tipError: string;
}

// ---- Reducer Action ----

export type FetchScope = "tips" | "tip";

export type FetchRequestedAction = {
  type: TipActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: TipActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: TipState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: TipActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchTipSucceededServerAction = {
  type: TipActionTypes.FETCH_TIP_SUCCEEDED_SERVER;
  payload: FetchTipResponseData | null;
};

// ---- Saga Action ----

export type FetchTipsSagaAction = {
  type: TipActionTypes.FETCH_TIPS_SAGA;
  payload?: FetchTipsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type TipAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchTipSucceededServerAction
  //
  | FetchTipsSagaAction;
