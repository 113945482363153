import { CommonActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchCaptchaCodeSagaAction,
  FetchSocialContactSagaAction,
  FetchSystemSettingsSagaAction,
  SubscribeAppSagaAction,
  SubmitContactSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CommonActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CommonActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CommonActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchCaptchaSaga = (
  payload?: FetchCaptchaCodeSagaAction["payload"],
  meta?: FetchCaptchaCodeSagaAction["meta"]
): FetchCaptchaCodeSagaAction => ({
  type: CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA,
  payload,
  meta,
});

export const fetchSocialContactSaga = (
  payload?: FetchSocialContactSagaAction["payload"],
  meta?: FetchSocialContactSagaAction["meta"]
): FetchSocialContactSagaAction => ({
  type: CommonActionTypes.FETCH_SOCIAL_CONTACT_SAGA,
  payload,
  meta,
});

export const fetchSystemSettingsSaga = (
  payload?: FetchSystemSettingsSagaAction["payload"],
  meta?: FetchSystemSettingsSagaAction["meta"]
): FetchSystemSettingsSagaAction => ({
  type: CommonActionTypes.FETCH_SYSTEM_SETTINGS_SAGA,
  payload,
  meta,
});

export const subscribeAppSaga = (
  payload: SubscribeAppSagaAction["payload"],
  meta?: SubscribeAppSagaAction["meta"]
): SubscribeAppSagaAction => ({
  type: CommonActionTypes.SUBSCRIBE_APP_SAGA,
  payload,
  meta,
});

export const submitContactSaga = (
  payload: SubmitContactSagaAction["payload"],
  meta?: SubmitContactSagaAction["meta"]
): SubmitContactSagaAction => ({
  type: CommonActionTypes.SUBMIT_CONTACT_SAGA,
  payload,
  meta,
});
