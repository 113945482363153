import { commonAxios } from "@/libs";

import type {
  FetchTipsPayload,
  FetchTipsResponseData,
  FetchTipPayload,
  FetchTipResponseData,
} from "./tip.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const tipApi = {
  fetchTips: (payload?: FetchTipsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchTipsResponseData>>(
      "content_management/tips/list.json",
      payload?.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchTip: (payload: FetchTipPayload) => {
    const { id, language } = payload.params;
    return commonAxios.post<AxiosResponseData<FetchTipResponseData>>(
      "content_management/tips/detail.json",
      {
        id,
        language,
      },
      {
        cancelToken: payload?.cancelToken,
        headers: payload?.headers,
      }
    );
  },
};

export default tipApi;
