import { commonAxios } from "@/libs";

import type { FetchTagsPayload, FetchTagsResponseData } from "./tag.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const tagApi = {
  fetchTags: (payload: FetchTagsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchTagsResponseData>>(
      "setting/tags/list.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default tagApi;
