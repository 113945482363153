import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { commonApi } from "@/utils/apis";

import { CommonActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchCaptchaCodeSagaAction,
  FetchScope,
  FetchSocialContactSagaAction,
  FetchSystemSettingsSagaAction,
  SubmitContactSagaAction,
  SubscribeAppSagaAction,
} from "./types";

function* fetchCaptchaCodeSaga(action: FetchCaptchaCodeSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {} } = action.meta || {};
  const scope = "captchaCode" as FetchScope;
  yield put(
    fetchRequested({
      scope,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.fetchCaptchaCode>> = yield call(
      commonApi.fetchCaptchaCode,
      {
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params?.capcha_number ?? "",
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchSocialContactSaga(action: FetchSocialContactSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "socialContact" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.fetchSocialContact>> = yield call(
      commonApi.fetchSocialContact,
      {
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchSystemSettingsSaga(action: FetchSystemSettingsSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "systemSettings" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.fetchSystemSettings>> = yield call(
      commonApi.fetchSystemSettings,
      {
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params ?? null,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* subscribeAppSaga(action: SubscribeAppSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.subscribeApp>> = yield call(
      commonApi.subscribeApp,
      {
        params,
        cancelToken,
      }
    );

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* submitContactSaga(action: SubmitContactSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.submitContact>> = yield call(
      commonApi.submitContact,
      {
        params,
        cancelToken,
      }
    );

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* commonSaga() {
  yield all([
    takeEvery(CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA, fetchCaptchaCodeSaga),
    takeEvery(
      CommonActionTypes.FETCH_SOCIAL_CONTACT_SAGA,
      fetchSocialContactSaga
    ),
    takeEvery(
      CommonActionTypes.FETCH_SYSTEM_SETTINGS_SAGA,
      fetchSystemSettingsSaga
    ),
    takeLatest(CommonActionTypes.SUBSCRIBE_APP_SAGA, subscribeAppSaga),
    takeLatest(CommonActionTypes.SUBMIT_CONTACT_SAGA, submitContactSaga),
  ]);
}

export default commonSaga;
