import { enUS, zhHK, zhCN, viVN, jaJP } from "@mui/material/locale";

import { notoSansFonts } from "@/utils/fonts";

export const DEFAULT_LOCALE =
  process.env.NODE_ENV === "development" ? "en-US" : "zh-HK";

export const localeToConfigMap = {
  "en-US": {
    label: "English",
    themeLocale: enUS,
    font: notoSansFonts.notoSans,
    imageSrc: "/images/svgs/en-us-flag.svg",
    requestedLanguage: "eng",
  },
  "zh-HK": {
    label: "繁體中文",
    themeLocale: zhHK,
    font: notoSansFonts.notoSansTC,
    imageSrc: "/images/svgs/zh-hk-flag.svg",
    requestedLanguage: "zho",
  },
  "zh-CN": {
    label: "简体中文",
    themeLocale: zhCN,
    font: notoSansFonts.notoSansSC,
    imageSrc: "/images/svgs/zh-cn-flag.svg",
    requestedLanguage: "chi",
  },
  "vi-VN": {
    label: "Tiếng Việt",
    themeLocale: viVN,
    font: notoSansFonts.notoSans,
    imageSrc: "/images/svgs/vi-vn-flag.svg",
    requestedLanguage: "vie",
  },
  "ja-JP": {
    label: "日本語",
    themeLocale: jaJP,
    font: notoSansFonts.notoSansJP,
    imageSrc: "/images/svgs/ja-jp-flag.svg",
    requestedLanguage: "jp",
  },
} as Record<
  string,
  {
    label: string;
    themeLocale: typeof enUS;
    font: typeof notoSansFonts.notoSans;
    imageSrc: string;
    requestedLanguage: string;
  }
>;

export const localeConfigs = Object.entries(localeToConfigMap).map(
  ([locale, config]) => ({
    locale,
    ...config,
  })
);

export const locales = Object.entries(localeToConfigMap).map(
  ([locale]) => locale
);
