import type {
  FetchSchoolCategoryResponseData,
  FetchSchoolCategoriesPayload,
  FetchSchoolCategoriesResponseData,
} from "@/utils/apis/schoolCategory";

export enum SchoolCategoryActionTypes {
  FETCH_REQUESTED = "@@schoolCategory/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@schoolCategory/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@schoolCategory/FETCH_FAILED",

  FETCH_SCHOOL_CATEGORY_SUCCEEDED_SERVER = "@@schoolCategory/FETCH_SCHOOL_CATEGORY_SUCCEEDED_SERVER",

  // Saga
  FETCH_SCHOOL_CATEGORY_SAGA = "@@schoolCategory/FETCH_SCHOOL_CATEGORY_SAGA",
  FETCH_SCHOOL_CATEGORIES_SAGA = "@@schoolCategory/FETCH_SCHOOL_CATEGORIES_SAGA",
}

// State

export interface SchoolCategoryState {
  hydrated?: boolean;

  schoolCategories: FetchSchoolCategoriesResponseData;
  schoolCategoriesLoading: boolean;
  schoolCategoriesError: string;

  schoolCategory: FetchSchoolCategoryResponseData | null;
  schoolCategoryLoading: boolean;
  schoolCategoryError: string;
}

// ---- Reducer Action ----

export type FetchScope = "schoolCategories" | "schoolCategory";

export type FetchRequestedAction = {
  type: SchoolCategoryActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: SchoolCategoryActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: SchoolCategoryState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: SchoolCategoryActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchSchoolCategorySucceededServerAction = {
  type: SchoolCategoryActionTypes.FETCH_SCHOOL_CATEGORY_SUCCEEDED_SERVER;
  payload: FetchSchoolCategoryResponseData | null;
};

// ---- Saga Action ----

export type FetchSchoolCategoriesSagaAction = {
  type: SchoolCategoryActionTypes.FETCH_SCHOOL_CATEGORIES_SAGA;
  payload?: FetchSchoolCategoriesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type SchoolCategoryAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchSchoolCategorySucceededServerAction
  //
  | FetchSchoolCategoriesSagaAction;
