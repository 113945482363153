import { commonAxios } from "@/libs";

import type {
  FetchNewsItemsPayload,
  FetchNewsItemsResponseData,
  FetchNewsItemPayload,
  FetchNewsItemResponseData,
} from "./newsItem.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const newsItemApi = {
  fetchNewsItems: (payload: FetchNewsItemsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchNewsItemsResponseData>>(
      "content_management/news/list.json",
      payload?.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchNewsItem: (payload: FetchNewsItemPayload) => {
    const { id } = payload.params;
    return commonAxios.post<AxiosResponseData<FetchNewsItemResponseData>>(
      "content_management/news/detail.json",
      {
        id,
      },
      {
        cancelToken: payload?.cancelToken,
        headers: payload?.headers,
      }
    );
  },
};

export default newsItemApi;
