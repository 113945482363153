import { all, fork } from "redux-saga/effects";

import auth from "./auth/sagas";
import service from "./service/sagas";
import common from "./common/sagas";
import banner from "./banner/sagas";
import school from "./school/sagas";
import schoolCategory from "./schoolCategory/sagas";
import newsItem from "./newsItem/sagas";
import city from "./city/sagas";
import tag from "./tag/sagas";
import accommodation from "./accommodation/sagas";
import tip from "./tip/sagas";
import tipCategory from "./tipCategory/sagas";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(service),
    fork(common),
    fork(banner),
    fork(school),
    fork(schoolCategory),
    fork(newsItem),
    fork(city),
    fork(tag),
    fork(accommodation),
    fork(tip),
    fork(tipCategory),
  ]);
}
