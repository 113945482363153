import { ServiceActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchServiceSucceededServerAction,
  // Saga
  FetchServicesSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ServiceActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ServiceActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ServiceActionTypes.FETCH_FAILED,
  payload,
});

export const fetchServiceSucceededServer = (
  payload: FetchServiceSucceededServerAction["payload"]
): FetchServiceSucceededServerAction => ({
  type: ServiceActionTypes.FETCH_SERVICE_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchServicesSaga = (
  payload?: FetchServicesSagaAction["payload"],
  meta?: FetchServicesSagaAction["meta"]
): FetchServicesSagaAction => ({
  type: ServiceActionTypes.FETCH_SERVICES_SAGA,
  payload,
  meta,
});
