import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { newsItemApi } from "@/utils/apis";

import { NewsItemActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchHomeNewsItemsSagaAction,
  FetchNewsItemsSagaAction,
  FetchScope,
} from "./types";

function* fetchNewsItemsSaga(action: FetchNewsItemsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "newsItems" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof newsItemApi.fetchNewsItems>> = yield call(
      newsItemApi.fetchNewsItems,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          isLoadMore,
          data: response.params?.News ?? [],
          count: response.params?.total_record ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchHomeNewsItemsSaga(action: FetchHomeNewsItemsSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "homeNewsItems" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof newsItemApi.fetchNewsItems>> = yield call(
      newsItemApi.fetchNewsItems,
      {
        params: {
          page: 1,
          limit: 6,
        },
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params?.News ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* newsItemSaga() {
  yield all([
    takeEvery(NewsItemActionTypes.FETCH_NEWS_ITEMS_SAGA, fetchNewsItemsSaga),
    takeEvery(
      NewsItemActionTypes.FETCH_HOME_NEWS_ITEMS_SAGA,
      fetchHomeNewsItemsSaga
    ),
  ]);
}

export default newsItemSaga;
