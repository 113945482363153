import { commonAxios } from "@/libs";

import type {
  FetchBannersPayload,
  FetchBannersResponseData,
} from "./banner.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const bannerApi = {
  fetchBanners: (payload: FetchBannersPayload) => {
    return commonAxios.post<AxiosResponseData<FetchBannersResponseData>>(
      "setting/banners/list.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default bannerApi;
