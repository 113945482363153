import type {
  FetchServiceResponseData,
  FetchServicesPayload,
  FetchServicesResponseData,
} from "@/utils/apis/service";

export enum ServiceActionTypes {
  FETCH_REQUESTED = "@@service/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@service/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@service/FETCH_FAILED",

  FETCH_SERVICE_SUCCEEDED_SERVER = "@@service/FETCH_SERVICE_SUCCEEDED_SERVER",

  // Saga
  FETCH_SERVICE_SAGA = "@@service/FETCH_SERVICE_SAGA",
  FETCH_SERVICES_SAGA = "@@service/FETCH_SERVICES_SAGA",
}

// State

export interface ServiceState {
  hydrated?: boolean;

  services: FetchServicesResponseData;
  servicesLoading: boolean;
  servicesError: string;

  service: FetchServiceResponseData | null;
  serviceLoading: boolean;
  serviceError: string;
}

// ---- Reducer Action ----

export type FetchScope = "services" | "service";

export type FetchRequestedAction = {
  type: ServiceActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: ServiceActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ServiceState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: ServiceActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchServiceSucceededServerAction = {
  type: ServiceActionTypes.FETCH_SERVICE_SUCCEEDED_SERVER;
  payload: FetchServiceResponseData | null;
};

// ---- Saga Action ----

export type FetchServicesSagaAction = {
  type: ServiceActionTypes.FETCH_SERVICES_SAGA;
  payload?: FetchServicesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type ServiceAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchServiceSucceededServerAction
  //
  | FetchServicesSagaAction;
