import { commonAxios } from "@/libs";

import type {
  FetchTipCategoriesPayload,
  FetchTipCategoriesResponseData,
} from "./tipCategory.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const tipCategoryApi = {
  fetchTipCategories: (payload: FetchTipCategoriesPayload) => {
    return commonAxios.post<AxiosResponseData<FetchTipCategoriesResponseData>>(
      "content_management/tip_categories/list.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default tipCategoryApi;
