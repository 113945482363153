import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/reducer";
import service from "./service/reducer";
import common from "./common/reducer";
import banner from "./banner/reducer";
import school from "./school/reducer";
import schoolCategory from "./schoolCategory/reducer";
import newsItem from "./newsItem/reducer";
import city from "./city/reducer";
import tag from "./tag/reducer";
import accommodation from "./accommodation/reducer";
import tip from "./tip/reducer";
import tipCategory from "./tipCategory/reducer";

const rootReducer = combineReducers({
  auth,
  service,
  banner,
  common,
  school,
  schoolCategory,
  newsItem,
  city,
  tag,
  accommodation,
  tip,
  tipCategory,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
