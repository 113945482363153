import { SchoolCategoryActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchSchoolCategorySucceededServerAction,
  // Saga
  FetchSchoolCategoriesSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: SchoolCategoryActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: SchoolCategoryActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: SchoolCategoryActionTypes.FETCH_FAILED,
  payload,
});

export const fetchSchoolCategorySucceededServer = (
  payload: FetchSchoolCategorySucceededServerAction["payload"]
): FetchSchoolCategorySucceededServerAction => ({
  type: SchoolCategoryActionTypes.FETCH_SCHOOL_CATEGORY_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchSchoolCategoriesSaga = (
  payload?: FetchSchoolCategoriesSagaAction["payload"],
  meta?: FetchSchoolCategoriesSagaAction["meta"]
): FetchSchoolCategoriesSagaAction => ({
  type: SchoolCategoryActionTypes.FETCH_SCHOOL_CATEGORIES_SAGA,
  payload,
  meta,
});
