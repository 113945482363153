import { SchoolActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchSchoolSucceededServerAction,
  // Saga
  FetchSchoolsSagaAction,
  FetchRecommendedSchoolsSagaAction,
  FetchAdmissionDateSchoolsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: SchoolActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: SchoolActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: SchoolActionTypes.FETCH_FAILED,
  payload,
});

export const fetchSchoolSucceededServer = (
  payload: FetchSchoolSucceededServerAction["payload"]
): FetchSchoolSucceededServerAction => ({
  type: SchoolActionTypes.FETCH_SCHOOL_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchSchoolsSaga = (
  payload?: FetchSchoolsSagaAction["payload"],
  meta?: FetchSchoolsSagaAction["meta"]
): FetchSchoolsSagaAction => ({
  type: SchoolActionTypes.FETCH_SCHOOLS_SAGA,
  payload,
  meta,
});

export const fetchRecommendedSchoolsSaga = (
  payload?: FetchRecommendedSchoolsSagaAction["payload"],
  meta?: FetchRecommendedSchoolsSagaAction["meta"]
): FetchRecommendedSchoolsSagaAction => ({
  type: SchoolActionTypes.FETCH_RECOMMENDED_SCHOOLS_SAGA,
  payload,
  meta,
});

export const fetchAdmissionDateSchoolsSaga = (
  payload: FetchAdmissionDateSchoolsSagaAction["payload"],
  meta?: FetchAdmissionDateSchoolsSagaAction["meta"]
): FetchAdmissionDateSchoolsSagaAction => ({
  type: SchoolActionTypes.FETCH_ADMISSION_DATE_SCHOOLS_SAGA,
  payload,
  meta,
});
