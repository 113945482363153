import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { schoolApi } from "@/utils/apis";

import { SchoolActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchAdmissionDateSchoolsSagaAction,
  FetchRecommendedSchoolsSagaAction,
  FetchSchoolsSagaAction,
  FetchScope,
} from "./types";

function* fetchSchoolsSaga(action: FetchSchoolsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "schools" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof schoolApi.fetchSchools>> = yield call(
      schoolApi.fetchSchools,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          isLoadMore,
          data: response.params?.School ?? [],
          count: response.params?.total_record ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchRecommendedSchoolsSaga(
  action: FetchRecommendedSchoolsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "recommendedSchools" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof schoolApi.fetchSchools>> = yield call(
      schoolApi.fetchSchools,
      {
        params: {
          ...params,
          is_recommend: 1,
        },
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params?.School ?? [],
          count: response.params?.total_record ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchAdmissionDateSchoolsSaga(
  action: FetchAdmissionDateSchoolsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "admissionDateSchools" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof schoolApi.fetchSchools>> = yield call(
      schoolApi.fetchSchools,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          isLoadMore,
          data: response.params?.School ?? [],
          count: response.params?.total_record ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* schoolSaga() {
  yield all([
    takeEvery(SchoolActionTypes.FETCH_SCHOOLS_SAGA, fetchSchoolsSaga),
    takeEvery(
      SchoolActionTypes.FETCH_RECOMMENDED_SCHOOLS_SAGA,
      fetchRecommendedSchoolsSaga
    ),
    takeEvery(
      SchoolActionTypes.FETCH_ADMISSION_DATE_SCHOOLS_SAGA,
      fetchAdmissionDateSchoolsSaga
    ),
  ]);
}

export default schoolSaga;
