import { createSelector } from "@reduxjs/toolkit";

import type { AppState } from "@/store";

export const selectSocialContact = (state: AppState) =>
  state.common.socialContact;

export const selectSystemSettings = (state: AppState) =>
  state.common.systemSettings;

export const selectSocialContactItem = createSelector(
  selectSocialContact,
  (socialContact) => {
    let whatsapp =
      socialContact?.social?.find((contact) => contact.slug === "whatsapp") ??
      null;

    const facebook =
      socialContact?.social?.find((contact) => contact.slug === "facebook") ??
      null;
    const instagram =
      socialContact?.social?.find((contact) => contact.slug === "instagram") ??
      null;
    const youtube =
      socialContact?.social?.find((contact) => contact.slug === "youtube") ??
      null;
    const phone =
      socialContact?.contact?.find(
        (contact) => contact.slug === "phone-contact"
      ) ?? null;
    const email =
      socialContact?.contact?.find(
        (contact) => contact.slug === "email-contact"
      ) ?? null;

    return {
      whatsapp,
      facebook,
      instagram,
      youtube,
      phone,
      email,
    };
  }
);

export const selectGenders = createSelector(
  selectSystemSettings,
  (systemSettings) => {
    return systemSettings?.gender ?? [];
  }
);

export const selectConsultingProjects = createSelector(
  selectSystemSettings,
  (systemSettings) => {
    return systemSettings?.project_consultings ?? [];
  }
);
