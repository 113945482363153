import type {
  FetchTipCategoriesPayload,
  FetchTipCategoriesResponseData,
} from "@/utils/apis/tipCategory";

export enum TipCategoryActionTypes {
  FETCH_REQUESTED = "@@tipCategory/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@tipCategory/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@tipCategory/FETCH_FAILED",

  // Saga
  FETCH_TIP_CATEGORIES_SAGA = "@@tipCategory/FETCH_TIP_CATEGORIES_SAGA",
}

// State

export interface TipCategoryState {
  tipCategories: FetchTipCategoriesResponseData;
  tipCategoriesLoading: boolean;
  tipCategoriesError: string;
}

// ---- Reducer Action ----

export type FetchScope = "tipCategories";

export type FetchRequestedAction = {
  type: TipCategoryActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: TipCategoryActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: TipCategoryState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: TipCategoryActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchTipCategoriesSagaAction = {
  type: TipCategoryActionTypes.FETCH_TIP_CATEGORIES_SAGA;
  payload?: FetchTipCategoriesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type TipCategoryAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchTipCategoriesSagaAction;
