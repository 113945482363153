import { commonAxios } from "@/libs";

import type {
  FetchCitiesPayload,
  FetchCitiesResponseData,
} from "./city.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const cityApi = {
  fetchCities: (payload: FetchCitiesPayload) => {
    return commonAxios.post<AxiosResponseData<FetchCitiesResponseData>>(
      "setting/cities/list.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default cityApi;
