import { TipActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchTipSucceededServerAction,
  // Saga
  FetchTipsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: TipActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: TipActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: TipActionTypes.FETCH_FAILED,
  payload,
});

export const fetchTipSucceededServer = (
  payload: FetchTipSucceededServerAction["payload"]
): FetchTipSucceededServerAction => ({
  type: TipActionTypes.FETCH_TIP_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchTipsSaga = (
  payload?: FetchTipsSagaAction["payload"],
  meta?: FetchTipsSagaAction["meta"]
): FetchTipsSagaAction => ({
  type: TipActionTypes.FETCH_TIPS_SAGA,
  payload,
  meta,
});
