import type { FetchTagsPayload, FetchTagsResponseData } from "@/utils/apis/tag";

export enum TagActionTypes {
  FETCH_REQUESTED = "@@tag/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@tag/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@tag/FETCH_FAILED",

  // Saga
  FETCH_TAGS_SAGA = "@@tag/FETCH_TAGS_SAGA",
}

// State

export interface TagState {
  tags: FetchTagsResponseData;
  tagsLoading: boolean;
  tagsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "tags";

export type FetchRequestedAction = {
  type: TagActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: TagActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: TagState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: TagActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchTagsSagaAction = {
  type: TagActionTypes.FETCH_TAGS_SAGA;
  payload?: FetchTagsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type TagAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchTagsSagaAction;
