import { commonAxios } from "@/libs";

import type {
  FetchSchoolCategoriesPayload,
  FetchSchoolCategoriesResponseData,
  FetchSchoolCategoryPayload,
  FetchSchoolCategoryResponseData,
} from "./schoolCategory.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const schoolCategoryApi = {
  fetchSchoolCategories: (payload?: FetchSchoolCategoriesPayload) => {
    return commonAxios.post<
      AxiosResponseData<FetchSchoolCategoriesResponseData>
    >("school/school_categories/list.json", payload?.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  fetchSchoolCategory: (payload: FetchSchoolCategoryPayload) => {
    const { id, language } = payload.params;
    return commonAxios.post<AxiosResponseData<FetchSchoolCategoryResponseData>>(
      "school/school_categories/detail.json",
      {
        id,
        language,
      },
      {
        cancelToken: payload?.cancelToken,
        headers: payload?.headers,
      }
    );
  },
};

export default schoolCategoryApi;
