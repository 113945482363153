import { commonAxios } from "@/libs";

import type {
  FetchAccommodationsPayload,
  FetchAccommodationsResponseData,
  FetchAccommodationPayload,
  FetchAccommodationResponseData,
} from "./accommodation.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const accommodationApi = {
  fetchAccommodations: (payload: FetchAccommodationsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchAccommodationsResponseData>>(
      "accommodation/accommodation_categories/list.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchAccommodation: (payload: FetchAccommodationPayload) => {
    const { id } = payload.params;
    return commonAxios.post<AxiosResponseData<FetchAccommodationResponseData>>(
      "accommodation/accommodation_categories_details/list.json",
      {
        accommodation_id: id,
      },
      {
        cancelToken: payload?.cancelToken,
        headers: payload?.headers,
      }
    );
  },
};

export default accommodationApi;
