import { AccommodationActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchAccommodationSucceededServerAction,
  // Saga
  FetchAccommodationsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AccommodationActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AccommodationActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AccommodationActionTypes.FETCH_FAILED,
  payload,
});

export const fetchAccommodationSucceededServer = (
  payload: FetchAccommodationSucceededServerAction["payload"]
): FetchAccommodationSucceededServerAction => ({
  type: AccommodationActionTypes.FETCH_ACCOMMODATION_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchAccommodationsSaga = (
  payload?: FetchAccommodationsSagaAction["payload"],
  meta?: FetchAccommodationsSagaAction["meta"]
): FetchAccommodationsSagaAction => ({
  type: AccommodationActionTypes.FETCH_ACCOMMODATIONS_SAGA,
  payload,
  meta,
});
