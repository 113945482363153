import { commonAxios } from "@/libs";

import type {
  FetchServicesPayload,
  FetchServicesResponseData,
  FetchServicePayload,
  FetchServiceResponseData,
} from "./service.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const serviceApi = {
  fetchServices: (payload: FetchServicesPayload) => {
    return commonAxios.post<AxiosResponseData<FetchServicesResponseData>>(
      "service/services/list.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchService: (payload: FetchServicePayload) => {
    const { id, language } = payload.params;
    return commonAxios.post<AxiosResponseData<FetchServiceResponseData>>(
      "service/service_details/list.json",
      {
        service_id: id,
        language,
      },
      {
        cancelToken: payload?.cancelToken,
        headers: payload?.headers,
      }
    );
  },
};

export default serviceApi;
