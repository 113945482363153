import { commonAxios } from "@/libs";

import type {
  FetchSettingsPayload,
  FetchSettingsResponseData,
  FetchSocialContactPayload,
  FetchSocialContactResponseData,
  SubscribeAppPayload,
  SubmitContactPayload,
  FetchSystemSettingsPayload,
  FetchSystemSettingsResponseData,
  FetchCaptchaCodePayload,
  FetchCaptchaCodeResponseData,
} from "./common.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const commonApi = {
  fetchSettings: (payload: FetchSettingsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchSettingsResponseData>>(
      "settings",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchSocialContact: (payload: FetchSocialContactPayload) => {
    return commonAxios.post<AxiosResponseData<FetchSocialContactResponseData>>(
      "setting/settings/get_setting_social_contact.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchSystemSettings: (payload: FetchSystemSettingsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchSystemSettingsResponseData>>(
      "setting/settings/get_data_settings.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchCaptchaCode: (payload: FetchCaptchaCodePayload) => {
    return commonAxios.post<AxiosResponseData<FetchCaptchaCodeResponseData>>(
      "cust_relation_management/request_informations/get_captcha_code.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
        withCredentials: true,
      }
    );
  },
  subscribeApp: (payload: SubscribeAppPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "cust_relation_management/subscribers/create_subcribers.json",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  submitContact: (payload: SubmitContactPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "cust_relation_management/request_informations/create_request_info.json",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
        withCredentials: true,
      }
    );
  },
};

export default commonApi;
