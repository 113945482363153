import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { tipApi } from "@/utils/apis";

import { TipActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type { FetchTipsSagaAction, FetchScope } from "./types";

function* fetchTipsSaga(action: FetchTipsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "tips" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const { data: response }: Awaited<ReturnType<typeof tipApi.fetchTips>> =
      yield call(tipApi.fetchTips, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params?.Tip ?? [],
          count: response.params?.total_record ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* tipSaga() {
  yield all([takeEvery(TipActionTypes.FETCH_TIPS_SAGA, fetchTipsSaga)]);
}

export default tipSaga;
