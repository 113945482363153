import type {
  FetchCitiesPayload,
  FetchCitiesResponseData,
} from "@/utils/apis/city";

export enum CityActionTypes {
  FETCH_REQUESTED = "@@city/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@city/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@city/FETCH_FAILED",

  // Saga
  FETCH_CITIES_SAGA = "@@city/FETCH_CITIES_SAGA",
}

// State

export interface CityState {
  cities: FetchCitiesResponseData;
  citiesLoading: boolean;
  citiesError: string;
}

// ---- Reducer Action ----

export type FetchScope = "cities";

export type FetchRequestedAction = {
  type: CityActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: CityActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CityState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: CityActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchCitiesSagaAction = {
  type: CityActionTypes.FETCH_CITIES_SAGA;
  payload?: FetchCitiesPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type CityAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchCitiesSagaAction;
