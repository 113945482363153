import { commonAxios } from "@/libs";

import type {
  FetchSchoolsPayload,
  FetchSchoolsResponseData,
  FetchSchoolPayload,
  FetchSchoolResponseData,
} from "./school.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const schoolApi = {
  fetchSchools: (payload?: FetchSchoolsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchSchoolsResponseData>>(
      "school/schools/list.json",
      payload?.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchSchool: (payload: FetchSchoolPayload) => {
    const { id } = payload.params;
    return commonAxios.post<AxiosResponseData<FetchSchoolResponseData>>(
      "school/schools/detail.json",
      {
        id,
      },
      {
        cancelToken: payload?.cancelToken,
        headers: payload?.headers,
      }
    );
  },
};

export default schoolApi;
