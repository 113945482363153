import type {
  FetchNewsItemResponseData,
  FetchNewsItemsPayload,
  FetchNewsItemsResponseData,
} from "@/utils/apis/newsItem";

export enum NewsItemActionTypes {
  FETCH_REQUESTED = "@@newsItem/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@newsItem/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@newsItem/FETCH_FAILED",

  FETCH_NEWS_ITEM_SUCCEEDED_SERVER = "@@newsItem/FETCH_NEWS_ITEM_SUCCEEDED_SERVER",

  // Saga
  FETCH_NEWS_ITEM_SAGA = "@@newsItem/FETCH_NEWS_ITEM_SAGA",
  FETCH_NEWS_ITEMS_SAGA = "@@newsItem/FETCH_NEWS_ITEMS_SAGA",
  FETCH_HOME_NEWS_ITEMS_SAGA = "@@newsItem/FETCH_HOME_NEWS_ITEMS_SAGA",
  FETCH_ADMISSION_DATE_NEWS_ITEMS_SAGA = "@@newsItem/FETCH_ADMISSION_DATE_NEWS_ITEMS_SAGA",
}

// State

export interface NewsItemState {
  hydrated?: boolean;

  newsItems: FetchNewsItemsResponseData["News"];
  newsItemsLoading: boolean;
  newsItemsError: string;
  newsItemsCount: number;

  newsItem: FetchNewsItemResponseData | null;
  newsItemLoading: boolean;
  newsItemError: string;

  homeNewsItems: FetchNewsItemsResponseData["News"];
  homeNewsItemsLoading: boolean;
  homeNewsItemsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "newsItems" | "newsItem" | "homeNewsItems";

export type FetchRequestedAction = {
  type: NewsItemActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: NewsItemActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: NewsItemState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: NewsItemActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchNewsItemSucceededServerAction = {
  type: NewsItemActionTypes.FETCH_NEWS_ITEM_SUCCEEDED_SERVER;
  payload: FetchNewsItemResponseData | null;
};

// ---- Saga Action ----

export type FetchNewsItemsSagaAction = {
  type: NewsItemActionTypes.FETCH_NEWS_ITEMS_SAGA;
  payload?: FetchNewsItemsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchHomeNewsItemsSagaAction = {
  type: NewsItemActionTypes.FETCH_HOME_NEWS_ITEMS_SAGA;
  payload?: Pick<FetchNewsItemsPayload, "cancelToken">;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type NewsItemAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchNewsItemSucceededServerAction
  //
  | FetchNewsItemsSagaAction
  | FetchHomeNewsItemsSagaAction;
